@font-face {
    font-family: 'Mogila';
    src: url('../fonts/Mogila.otf') format('opentype');
  }
  @import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");

.animated-heading {
 
    font-family:"Julius Sans One", sans-serif;
    font-weight: 200;
    font-size: clamp(35px, 4vw, 60px);
    color: black;
    overflow: hidden; /* Stellt sicher, dass Buchstaben nicht vorzeitig sichtbar sind */
    white-space: nowrap; /* Verhindert Zeilenumbrüche während der Animation */
    
    text-align: left; /* Zentriert den Text der Überschrift */
    margin: 20px 0;
    margin-left: auto;
    opacity: 0; /* Starte unsichtbar, bis inView wahr ist */
    transition: transform 0.3s ease;
  }

.animated-heading:hover {
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

  .heading-wrapper {
    min-height: 60px;
  }
  
  
  .animated-heading.animate {
    opacity: 1;
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @media (max-width: 600px) {
    .animated-heading {

      color: black;

      
    }
    .heading-wrapper {
      min-height: 50px;
    }
    
  }