.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 20px auto;
    gap: 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    font-family: inherit;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .contact-form button {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    background-color: #87b1bf;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #4c717d;
  }
  