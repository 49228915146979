@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@font-face {
    font-family: 'Mogila';
    src: url('../../fonts/Mogila.otf') format('opentype');
  }

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Nutzt die gesamte Höhe des Viewports */
    background-color: #f5f5f5; /* Hintergrundfarbe des Login-Bereichs */
  }
  
  /* Stil für das Passworteingabefeld */
  .login input[type="password"] {
    padding: 10px;
    margin-bottom: 20px; /* Abstand zum Login-Button */
    border: 1px solid #ccc; /* Rand des Eingabefelds */
    border-radius: 5px; /* Abgerundete Ecken für das Eingabefeld */
    width: 250px; /* Breite des Eingabefelds */
  }
  
  /* Stil für den Login-Button */
  .login .loginButton {
    padding: 10px 20px;
    border: none;
    background-color: #007bff; /* Hintergrundfarbe des Buttons */
    color: white; /* Textfarbe des Buttons */
    border-radius: 5px; /* Abgerundete Ecken für den Button */
    cursor: pointer; /* Ändert den Cursor beim Überfahren des Buttons */
    transition: background-color 0.3s ease; /* Glatter Übergang für den Hover-Effekt */
  }
  
  /* Hover-Effekt für den Login-Button */
  .login .loginButton:hover {
    background-color: #0056b3; /* Dunklere Hintergrundfarbe beim Überfahren */
  }
.article {
    height: 80%;
    
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch; 
    z-index: 2;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #c1b3a5; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.imagecontainer {
  width: 100%; /* oder eine spezifische Breite */
  height: 98vh; /* Höhe des Containers, passt die Abschneidehöhe an */
  overflow: hidden; /* Verbirgt den überstehenden Teil des Bildes */
  position: relative; /* Ermöglicht die absolute Positionierung des Kind-Elements */
  display: flex;
  justify-content: center; /* Zentriert das Bild horizontal */
}

.first {
   
  position: absolute;
  top: 50%; /* Zentriert das Bild vertikal */
  transform: translateY(-32%); /* Verschiebt das Bild zurück, um es zu zentrieren */
  min-width: 100%; /* Stellt sicher, dass das Bild mindestens so breit wie der Container ist */
  height: auto;
}

.banner{
    height: 20px;
}


.alltop{
    margin-top: 20%;
  }

  .blur::after {
    filter: blur(8px); /* Erhöhe den Wert für mehr Unschärfe */
    transition: filter 0.5s ease-out; /* Anpassbar für Geschwindigkeit und Effekt */
  }
  
  .clear {
    filter: blur(0); /* Entfernt den Blureffekt */
    transition: filter 0.5s ease-out; 
  }
.logoSvg {
    width: 30%;
    height: auto;
    fill:white;
    z-index: 3;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 10%;
    left: 50%;
    right: 0;
    height: fit-content;
    margin: auto;
    
    /*width: 10px; 
    height: 10px; 
    margin: 10px;

    */ /* Damit der Container nicht breiter als das Bild ist */

}

.header {
    display: flex;
    flex-direction: column;
    /* Elemente vertikal anordnen */
    align-items: left;
    /* Elemente links ausrichten */
    font-family: 'Nunito', sans-serif;
    font-weight: 200;
    font-size: clamp(32px, 5vw, 90px);
    color: white;
    position: absolute;
    top: 31%;
    left: 3%;
    /* Zentriert den .header horizontal */
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
    margin-bottom: 20px;
  
}

.conn {
  font-size: clamp(55px, 5vw, 90px)!important;
    transform: translateX(-50%); 
    margin-bottom: 20px;
    /* Keine zusätzliche Anpassung nötig, wenn dieses Element linksbündig sein soll */
}

.firstHead {
  font-size: clamp(42px, 4vw, 90px) !important;
  /* Keine zusätzliche Anpassung nötig, wenn dieses Element linksbündig sein soll */
}
.secondHead{
 
  margin-bottom: 20px;
  font-size: clamp(37px, 3.5vw, 90px) !important;
  /* Keine zusätzliche Anpassung nötig, wenn dieses Element linksbündig sein soll */
}

.shap {/* Verschiebt das zweite Element nach rechts */
    /* Alternativ kannst du padding-left verwenden, wenn das für dein Layout besser passt */
}

.scroll-down-arrow {
    position: absolute;
    bottom: 70px; /* Anpassen basierend auf Ihrem Layout */
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px; /* Größe des Pfeils anpassen */
    cursor: pointer;
    /* Optional: Stilisierung für bessere Sichtbarkeit */
    stroke: #FFF; /* Oder eine andere Farbe, die zu Ihrem Design passt */
    text-shadow: 0 0 8px rgba(0,0,0,0.5); /* Sorgt für bessere Lesbarkeit auf unterschiedlichen Hintergründen */
    z-index: 1000; 
  }
  .arrow-second{
    stroke:black;
  }
  .arrow-third{
  bottom:-5%;

    stroke:black;
  }
  .mid-part{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10%;
  }


  .final-section {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-size: 18px;
    line-height: 1.6;
  }

.philo{
    
    text-align: center;

    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.heading2{
    font-family:'Mogila', sans-serif;
    font-weight: 300;
    font-size: 3.5vw;
}
.containe {
    display: flex;
    align-items: center;
    position: relative;
    position: relative;
    display: flex;

    align-items: center;
    justify-content: center;
    height: auto;
  /* Anpassen basierend auf dem Inhalt */
  }
  
  .colum {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }
  
  .text {
    padding: 30px;
    margin:20px 0; /* Für Abstand zwischen den Feldern */
    background-color: #87b1bf;
    color:white;
    border-radius: 20px;
    font-size: larger;
    font-family:'Mogila', sans-serif;
    font-weight: 200;
  }
  .main-text {
    margin: 5%;
    margin-top: 10%;
    max-width: 40%; /* Maximale Breite des Fließtextes */
    text-align: center; /* Zentrierter Text */
    margin-bottom: 1vw; /* Abstand zum unteren Inhalt */
    font-weight: 200;
    font-size: clamp(15px, 1vw, 27px);
  }
  
  .end-text {
    margin: 5%;

    max-width: 40%; /* Maximale Breite des Fließtextes */
    text-align: center; /* Zentrierter Text */
    margin-bottom: 40px; /* Abstand zum unteren Inhalt */
    font-weight: 500;
    font-size: 1vw;
    position: absolute;
    left: 50%;
    transform: translateX(-62%) translateY(700%);

  }
  
  .center-title {
    position: absolute;
    left: 50%;
    transform: translateX(-45%) translateY(-80%);
  }
  
  .first-part {
    max-width: 100%; /* oder einen bestimmten Prozentsatz */
     /* Zentriert den Container */
    margin-inline: auto;
    text-align: center;
    margin-bottom: 5%;
     /* Teilt den Container in zwei Spalten mit unterschiedlichen Größen */

  }
  .section-text {
    
    margin-top: 5%;
  }  
  
    .second{
      grid-area: image;
      height: 80vh;
      width: auto;
      
      margin-top: 0%;
      justify-self: center;
  }



  .leftText{
    font-family: 'Nunito', sans-serif;
    font-weight: 100!important;
    font-size: clamp(37px, 5vw, 40px);
    max-width: 95%;
    margin-inline: auto;

}

@media (max-width: 768px) {
  .imagecontainer {
    width: 100%; /* oder eine spezifische Breite */
    height: 87vh;
  }
  .arrow-second{
    top: -30%;
   }
  .first-part{
    max-width: 80%;
    
    margin-top: 10%;
    margin-bottom: 20%;

  }
  .first{
    transform: translateY(-42%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    height: 105vh
  }
  
  .section-text {
    
  
    margin-right: 0%;
    margin-bottom: 10%;
  }  
  .leftText{

    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 30px;
    text-align: left;

}

  .second{
    display: none;
  
  }
.listli::marker{
  color: rgba(0, 0, 0, 0);
}
  .arrow-third{
  bottom: 0%;
  }

  
    .header { 
        font-size: 40px;
        text-align: center;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        height: fit-content;
        margin: auto;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);;
       
    }
  
    .conn {
         transform: translateX(0%); 
        margin-bottom: 0px;
    }

    .first {
        object-fit: cover;
        width: 100%;
        height: 100%;
        height: 100vh
    
    }
 
  .logoSvg{
    left: 45% !important;
    top: 15%;
    width: 60%;

   
    }

    .philo{
      text-align: center;
       margin-top: 25%;
      margin-bottom: 0%;
    
  }
  .header {
    font-size: 40px !important;
}

}

  
  /* Größere Schriftgröße für größere Fenster */
  @media (min-width: 1200px) {
    .header {
        font-size: 70px;
    }
    
  }
  