@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@font-face {
    font-family: 'Mogila';
    src: url('../../fonts/Mogila.otf') format('opentype');
  }

.about-me-container {
  margin-top: 3%;
  margin-inline: 13%;
  padding: 20px;
  font-family:'Nunito', sans-serif;

    font-weight: 300;

}

.imagecontainer {
  width: 100%; /* oder eine spezifische Breite */
  height: 100vh; /* Höhe des Containers, passt die Abschneidehöhe an */
  overflow: hidden; /* Verbirgt den überstehenden Teil des Bildes */
  position: relative; /* Ermöglicht die absolute Positionierung des Kind-Elements */
  display: flex;
  justify-content: center; /* Zentriert das Bild horizontal */
}

.coverPicture {
  position: absolute;
  top: 50%; /* Zentriert das Bild vertikal */
  transform: translateY(-35%); /* Verschiebt das Bild zurück, um es zu zentrieren */
  min-width: 100%; /* Stellt sicher, dass das Bild mindestens so breit wie der Container ist */
  height: auto; /* Erhält das Seitenverhältnis des Bildes */
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px; /* Kontrolliert die Größe der Icons */
  color: #c1b3a5; /* Passt die Farbe der Icons an */
  /* Weitere Stile nach Bedarf */
}
.hHead {

  text-align: center;
  margin-bottom: 20px;
  font-family:"Julius Sans One", sans-serif;
  font-weight: 300;
  font-size: 4.5vw;
 
  transition: transform 0.3s ease;
}
.hHead:hover {
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.h2 {  white-space: nowrap;
    font-family:'Nunito', sans-serif;
  font-size: 30px;

  text-align: center;
  background-color: #f0f0f0;
  padding: 5px;
  padding-inline: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.h3 { 

  font-family:'Nunito', sans-serif;
font-size: clamp(30px, 5vw, 30px);

text-align: center;
margin: auto ;

  font-weight: 300;

padding-inline: 20px;

margin-bottom: 2%;

}
.hAusbildung { 
  font-family:'Nunito', sans-serif;
font-size:30px;

text-align: center;
margin: auto ;

  font-weight: 300;

padding-inline: 20px;

}
.hJahre{
  font-family:'Nunito', sans-serif;
  white-space: nowrap;
  font-weight: 200;
}
.textAusbildung { 

  font-family:'Nunito', sans-serif;
font-size: clamp(20px, 5vw, 25px);

text-align: center;
margin: auto ;

  font-weight: 300;

padding-inline: 20px;

margin-bottom: 1%;

}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #c1b3a5; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.hLang {
  margin: auto;
  max-width: 80%;

  text-align: center;
  justify-self: center;
  margin-bottom: 10px;
  font-family:"Julius Sans One", sans-serif;
  font-weight: 300;
  font-size: clamp(40px, 6vw, 55px);

  transition: transform 0.3s ease;
}
.hLang:hover {
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.hLinked { 
  display: flex;
  margin-inline: auto;
  font-size: clamp(40px, 6vw, 55px);

  justify-content: center;
  transition: transform 0.3s ease;
  margin-bottom: 10%;
  margin-top: 4%;
  transform-origin: center; 
  overflow: hidden;
  min-width: 0; /* Verhindert, dass das Element zu klein wird */
  max-width: 70%;
}
.hLinked:hover { 
  transform: scale(1.05);
}
/* AboutMe.module.css */

.row {
  display: flex;
  
  margin-bottom: 20px;
}

.tableElement {
  font-size: 23px !important;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 90%; /* Passt die Breite jedes Tabellenelements an */
  align-items: center;
}
.tableElement .p{
  margin-left: 10%;
  align-self: flex-start;
}
.tableElement:hover {
  .icon{transform: scale(1.05) ;}
  .h2{transform: scale(1.05) ;}

}

.icon {
  width: 100px; /* Anpassen nach Bedarf */
  height: auto;
  transition: transform 0.3s ease;
}

.twocolumnscontainer {
  display: block;
  justify-content: center;
  text-align: center;
  width: 80%; /* Maximale Breite des Containers */
  margin: auto; /* Zentriert den Container */
  margin-bottom: 5%;
  margin-top: 5%;
}

.column {
  
  font-size: clamp(23px, 3vw, 23px);
  flex: 1; /* Lässt beide Spalten den verfügbaren Platz gleichmäßig aufteilen */
  padding: 20px; /* Fügt innen Abstand hinzu */
  font-weight: 300;
}

ul.aboutlist {
  font-weight: 300;

  list-style-type: none; /* Entfernt Standard-Listensymbole */
/* Entfernt Standard-Padding der Liste */
}
ul.aboutlist  li {

  position: relative; /* Ermöglicht die korrekte Positionierung des ::before Inhalts */
  text-indent: 0; /* Zieht die erste Zeile jedes Listenelements nach links */
  padding-left: 1em; /* Fügt links Padding hinzu, damit der Text nicht über den Bindestrich geht */
}
ul.aboutlist  li::before {
  content: "-"; /* Fügt den Bindestrich hinzu */
  position: absolute; /* Ermöglicht die absolute Positionierung des Bindestrichs */
  left: 0; /* Positioniert den Bindestrich am Anfang des Elements */
}

@media (max-width: 768px) {

  .h2 { 
  font-size: 23px;
  }
  .hAusbildung { 
  font-size:25px;

  
  }
  .about-me-container {
    margin-top: 5%;
    margin-inline: 0%;
    padding: 20px;
  }
  .hHead {
    font-size: 10.5vw;

  }
  .row {
    flex-direction: column;
    align-items: center;
  }

  .tableElement {
    width: 100%; /* Breitere Elemente auf kleineren Bildschirmen */
  }

  .imagecontainer {
 
    margin-bottom: -10%;
    width: 100%; /* oder eine spezifische Breite */
     /* Höhe des Containers, passt die Abschneidehöhe an */
    overflow: hidden; /* Verbirgt den überstehenden Teil des Bildes */
    position: relative; /* Ermöglicht die absolute Positionierung des Kind-Elements */
    display: flex;
    justify-content: center; /* Zentriert das Bild horizontal */
  }
  
  .coverPicture {
    position: absolute;
 /* Zentriert das Bild vertikal *//* Verschiebt das Bild zurück, um es zu zentrieren */
    min-width: 100%; /* Stellt sicher, dass das Bild mindestens so breit wie der Container ist */
    height: auto; /* Erhält das Seitenverhältnis des Bildes */
  }
  .coverPicture { 

    transform: none; 
    position: absolute;
    top: 10%; /* Zentriert das Bild vertikal */
    transform: 0; /* Verschiebt das Bild zurück, um es zu zentrieren */
    min-width: 100%; /* Stellt sicher, dass das Bild mindestens so breit wie der Container ist */
    height: auto; /* Erhält das Seitenverhältnis des Bildes */
  }
  .twocolumnscontainer {
    width: 100%;
    margin-inline: none;
    display: block;
    text-align: left;
  }
  
}
