@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");

.centered {
  margin-inline: auto;
  width: 50%;
  margin-top:2%;

 margin-bottom: 10%;
  text-align: center;
  font-family:"Julius Sans One", sans-serif; /* Modern und schlicht */
}


.fließContent{
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: clamp(20px, 3vw, 20px) !important;
  margin-bottom: 2%;
  display: block;
}




/* Stil für den Header, der immer sichtbar ist */


/* Stil für den Inhalt, der beim Klicken ein-/ausgeblendet wird */




.tabbuttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabbuttons button {
  border: 2px solid #cccccc;
  background-color: #ffffff;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}

.tabbuttons button.active {
  border-color: #007bff;
  background-color: #007bff;
  color: #ffffff;
}

.tabcontent {
  text-align: center;
}
.tabscontainer {
  width: 80%;
  max-width: 90%;
  margin: auto;
  justify-content: center;
}

.tabs {
  max-width: 100%;
  margin: auto;
  display: flex; /* Sorgt dafür, dass die Buttons in einer Zeile angeordnet werden */
  /* Zentriert die Buttons horizontal im Container */
  align-items: center;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: clamp(25px, 3vw, 30px) !important;

}

.tab {
  border: none;
  
margin-inline: 2%;
  max-width: 70%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  padding: 10px 4%;
  border-radius: 30px;
  font-family:"Julius Sans One", sans-serif;

  outline: none;
  background-color: #f0f0f0;
  font-weight: 400;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.tab.active {
  border: solid 1px;
  background-color: #bfbfbf;;
  color: white;

}
.tab:hover{
  background-color: #bfbfbf;;
  transform: scale(1.05)!important;
}
.tabs::marker{
  color: rgba(0, 0, 0, 0);
}
.tabcontent {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 20px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .tabcontent {
    width: 120%;
 
  }
.tabs{
  display: list-item;

}
    .tab { 
      max-width: 100%;
      margin-top:3%;
      width: 100%;
      margin-inline: none;}

      .tabscontainer {
        width: 100%;
      }    
      .centered {
        margin-inline: auto;
        width: 100%;}
}
