.article2 {
  margin-top: -2%;
  height: 60%;

  position: relative;
  overflow: hidden;
  z-index: 2;
}

.cover {
  object-fit: cover;
  width: 100%;
  
  height: 102.2vh;
  object-position: center top;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #c1b3a5; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.services-list {
  font-family: Arial, sans-serif;
  margin: 10px auto;
  padding: 10px;
margin-top: 3%;
}

.services-list h2 {
 
  text-align: center;
  margin-bottom: 20px;
}



.header2 {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  /* Elemente vertikal anordnen */
  align-items: center;
  /* Elemente links ausrichten */
  font-family: 'Nunito', sans-serif;
  font-weight: 300;

  color: white;
  position: absolute;
  top: 75%;
  left: 50%;
  /* Zentriert den .header horizontal */
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  margin-bottom: 20px;
}

.leistung {
  max-width: 90%;
  margin: auto;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  font-family:"Julius Sans One", sans-serif;
  font-weight: 300;
  font-size: 4.5vw;

  transition: transform 0.3s ease;
}

.leistung:hover {
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.alltop2 {
  margin-bottom: 10%;

}



.felder-beratung {

  width: 90%;
  /* Stellt sicher, dass die Felder die volle Breite ihrer Spalte einnehmen */
  border: 2px solid #c1b3a5;
  border-radius: 20px;
  margin: 5% 0;
  /* Angepasster vertikaler Abstand */
  margin-left: 5%;
  overflow: hidden;
  font-size: 30px;
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s, background-color 1s;
  transform: scale(1);
}

.icon-wrapper-beratung {
  border-bottom: 2px solid #c1b3a5;
  /* Farbe der Linie zwischen Logo und Text */
  padding: 20px;
  /* Abstand innerhalb des Logo-Bereiches */
  text-align: center;
  transition: background-color 1s;
}


.title-wrapper-beratung {
  font-size: 24px;
  /* Anpassen nach Bedarf */
  font-weight: 700;
  /* Für eine kräftige Überschrift */
  color: #c1b3a5;
  /* Farbanpassung */
  text-align: center;
  padding: 10px;
  /* Abstand */
}

.text-wrapper-beratung {
  background-color: #c1b3a5;
  color: white;
  text-align: left;
  font-weight: 300;
  padding: 20px;
  font-size: 23px;
  /* Anpassen nach Bedarf */
}


.icon-style-beratung {
  font-size: 48px;
  /* Größe des Icons */
  color: #c1b3a5;
  /* Farbe des Icons */
}

.felder-beratung:hover {
  transform: scale(1.03);
  /* Skaliert das Element leicht */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Fügt einen Schatten hinzu */
}

.felder-beratung:hover .icon-wrapper-beratung {
  background-color: #c1b3a5;
  /* Farbe des Textfeldes wird zum Logo-Hintergrund */
}

.felder-beratung:hover .text-wrapper-beratung {
  background-color: transparent;
  /* Text-Hintergrund wird transparent */
  color: #c1b3a5;
  /* Textfarbe ändert sich zur Umrandungsfarbe */
}

.felder-beratung:hover .icon-style-beratung {
  color: white;
  /* Icon-Farbe wird zu weiß */
}

.grid-container-beratung {
  display: flex;
  justify-content: center;
  /* Zentriert den Container */
  flex-wrap: wrap;
  /* Erlaubt den Elementen, in die nächste Zeile zu fließen */
  gap: 20px;
  /* Abstand zwischen den Elementen */
  max-width: 1200px;
  /* Oder eine Breite nach Wahl */
  margin: auto;
  /* Zentriert den Container im übergeordneten Element */
}

.column-beratung {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Neu hinzugefügt, um die Felder zu strecken */
  justify-content: space-between;
  gap: 20px;
  /* Vertikaler Abstand zwischen den Feldern */
  width: 48%;
  /* Passt die Breite jeder Spalte an, um etwas Raum zwischen ihnen zu lassen */
}

.leftShift {
  margin-top: 00%;
}
.IndicatorMobil{
  display: none;
}
.h3 { 
  font-family:"Julius Sans One", sans-serif;
  font-weight: 300;
  font-size: clamp(40px, 5vw, 70px);

text-align: center;
justify-self: center;
margin-inline: auto;
margin-top: 5%;

max-width: 90%;
margin-bottom: 10px;
transition: transform 0.3s ease;
}
.h3:hover { 
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


ul.custom-list {
  list-style-type: none; /* Entfernt Standard-Listensymbole */
  padding-left: 0; /* Entfernt Standard-Padding der Liste */
}
ul.custom-list li {
  position: relative; /* Ermöglicht die korrekte Positionierung des ::before Inhalts */
  text-indent: 0; /* Zieht die erste Zeile jedes Listenelements nach links */
  padding-left: 1em; /* Fügt links Padding hinzu, damit der Text nicht über den Bindestrich geht */
}
ul.custom-list li::before {
  content: "-"; /* Fügt den Bindestrich hinzu */
  position: absolute; /* Ermöglicht die absolute Positionierung des Bindestrichs */
  left: 0; /* Positioniert den Bindestrich am Anfang des Elements */
}


@media (max-width: 768px) {
  .article2{
    margin-top: 3%;
  }
  .cover {
    width: 100%; /* oder eine spezifische Breite */
    height: 87vh;
  }
  .IndicatorMobil{
    display: block;
  }
  .leistung {
    font-weight: 300;
    font-size: 55px;
  }
  .services-list {
    font-family: Arial, sans-serif;
    margin-top: 20px;
    margin: none;
    margin-left: none;
    padding:0;
  
  }

  .grid-container-beratung {
    flex-direction: column;
    align-items: center;
    /* Zentriert die Felder in der einzelnen Spalte */
  }

  .column-beratung {
    width: 95%;
    margin-inline: auto;
    /* Die Spalten nehmen die volle Breite ein, sodass sie zu einer Spalte werden */
  }

  .felder-beratung {
    margin: none;
  

    /* Optional: Anpassung der Breite der Felder für Mobilgeräte */
  }

  .leftShift {
    margin-top: 0%;
  }



  .header2 {
    width: 80%;
    white-space: initial;
    text-align: center;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  }
}