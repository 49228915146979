


.grid-container {
  padding-top: 1%;
  display: grid;
  grid-template-columns:2fr ;
  /* Mittlere Spalte doppelt so breit */
  max-width: 55%;
  margin-inline: auto;

  /* Drei Zeilen mit gleicher Höhe */
  gap: 10px;
  /* Abstand zwischen den Zellen */
  align-items: center;
  justify-content: center;
  /* Beginnt Inhalte am Anfang der Zelle */
  margin-bottom: 0%;

}

/* Linke Spalte */
.grid-container>.left {
  grid-column: 1;
  margin-left: 12%;
}

/* Mittlere Spalte */
.grid-container>.center {
  grid-column: 2;
  grid-row: 1 / span 3;
  /* Überschrift und Text nehmen alle drei Zeilen ein */
  display: flex;
  flex-direction: column;
  /* Stapelt Überschrift und Text vertikal */
}

/* Rechte Spalte */
.grid-container>.right {
  grid-column: 3;
  align-items: center;
  margin-left: 12%;
}



.left.top {
  grid-row: 1;
}

/* Nimmt Zeile 1 und 2 ein */
.left.middle {
  grid-row: 2;
}

/* Beginnt in Zeile 2 und geht bis Zeile 3 */
.left.bottom {
  grid-row: 3;
}

/* Nur Zeile 3 */

/* Rechte Spalte - versetzt */
.right.top {
  grid-row: 1;
}

/* Nur Zeile 1 */
.right.middle {
  grid-row: 2;
}

/* Beginnt in Zeile 2 und geht bis Zeile 3 */
.right.bottom {
  grid-row: 3;
}

.colum {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}


.felder {
  border: 2px solid #87b1bf; /* Farbe der Umrandung des gesamten Feldes */
  border-radius: 20px;
  font-family: 'Mogila', sans-serif;
  margin: 10px auto;
  max-width: 80%;
  overflow: hidden;
  border-radius: 20px;
  font-weight: 300;
  font-size: clamp(23px, 3vw, 25px) !important;/* Verhindert, dass Inhalte außerhalb der Grenzen angezeigt werden */
  transition: color 0.3s, transform 0.3s, box-shadow 0.3s, background-color 1s;
  transform: scale(1);
}

.icon-wrapper {
  border-bottom: 2px solid #87b1bf; /* Farbe der Linie zwischen Logo und Text */
  padding: 20px; /* Abstand innerhalb des Logo-Bereiches */
  text-align: center;
  transition: background-color 1s; 
}


.text-wrapper {
  background-color: #87b1bf; /* Hintergrundfarbe des Textbereichs */
  color: white; /* Textfarbe */
  text-align: center;
  transition: background-color 1s, color 1s;
}

.icon-style {
  font-size: 48px; /* Größe des Icons */
  color: #87b1bf; /* Farbe des Icons */
}
.felder:hover {
  transform: scale(1.2); /* Skaliert das Element leicht */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Fügt einen Schatten hinzu */
}

.felder:hover .icon-wrapper {
  background-color: #87b1bf; /* Farbe des Textfeldes wird zum Logo-Hintergrund */
}

.felder:hover .text-wrapper {
  background-color: transparent; /* Text-Hintergrund wird transparent */
  color: #87b1bf; /* Textfarbe ändert sich zur Umrandungsfarbe */
}

.felder:hover .icon-style {
  color: white; /* Icon-Farbe wird zu weiß */
}

.main {
  margin: 3%;
  /* Maximale Breite des Fließtextes */
  text-align: left;
  /* Zentrierter Text */
  
  /* Abstand zum unteren Inhalt */
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 23px !important;
}

.end {

  text-align: center;
  justify-content: center;
  
  font-family:"Julius Sans One", sans-serif;
  /* Zentrierter Text */
  transform:  translateY(-100%);
  /* Abstand zum unteren Inhalt */
  font-weight: 500;
  font-size: clamp(30px, 4vw, 40px)!important;

}
.endslogan{
 
  font-size: clamp(35px, 4vw, 60px)!important;
  margin: auto;
  width: 99%;
  transition: transform 0.3s ease;
}
.endslogan:hover{

    transform: scale(1.05) ;
  
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
}
.noww1{
  white-space: nowrap;
 
}
.noww2{
  white-space: nowrap;

}
.center-title {
  position: absolute;
  left: 50%;
  transform: translateX(-45%) translateY(-80%);
}


.heading3 {
  max-width: 100%;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  justify-content: center;
  font-family:"Julius Sans One", sans-serif;
  font-weight: 300;
  font-size: clamp(50px, 5vw, 70px);
 
  transition: transform 0.3s ease;
}
.heading3:hover {
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
ul.pointList {

  font-weight: 300;

  margin-inline: auto;
  list-style-type: none; /* Entfernt Standard-Listensymbole */
/* Entfernt Standard-Padding der Liste */
}
ul.pointList  li {

  position: relative; /* Ermöglicht die korrekte Positionierung des ::before Inhalts */
  text-indent: 0; /* Zieht die erste Zeile jedes Listenelements nach links */
  padding-left: 1em; /* Fügt links Padding hinzu, damit der Text nicht über den Bindestrich geht */
}
ul.pointList  li::before {
  content: "-"; /* Fügt den Bindestrich hinzu */
  position: absolute; /* Ermöglicht die absolute Positionierung des Bindestrichs */
  left: 0; /* Positioniert den Bindestrich am Anfang des Elements */
}
@media (max-width: 768px) {

 

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    /* Mittlere Spalte doppelt so breit */
    /* Drei Zeilen mit gleicher Höhe */
    gap: 10px;
    /* Abstand zwischen den Zellen */
    align-items: center;
    margin-bottom: 20%;
    max-width: 100%;
  }

  .grid-container>.left {
    grid-column: 3;
    margin-left: 12%;
  }

  /* Mittlere Spalte */
  .grid-container>.center {
    margin-left: auto;
    margin-right: auto;
    grid-column: 1 / -1;
    /* Annahme, dass es sich um eine 3-Spalten-Grid handelt */
    grid-row: 1;
    display: flex;
    flex-direction: column;
    /* Stapelt Kinder vertikal */
    justify-content: center;
    /* Zentriert Kinder vertikal im Flex-Container */
    align-items: center;
    /* Stapelt Überschrift und Text vertikal */

  }

  /* Rechte Spalte */
  .grid-container>.right {
    display: none;
    grid-column: 3;
    align-items: center;
    margin-left: 12%;
  }


  .grid-container>.left {
    display: none;
    grid-column: 2;
  }

  .end {
    text-align: center;
    font-family:"Julius Sans One", sans-serif;
    /* Zentrierter Text */
    transform:  translateY(-10%);
    /* Abstand zum unteren Inhalt */
    font-weight: 500;
    font-size: 5.5vw;
  
  }
  .endslogan{
    transform:  translateY(-0%);
    font-size: 8vw;
  }

  .main{
    margin-inline: 8%;
   
  }

 
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Startposition leicht nach unten für den Einblende-Effekt */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Endposition */
  }
}

.initial-hide {
  opacity: 0;
  transform: translateY(20px); /* Stellen Sie sicher, dass Elemente ursprünglich verschoben sind */
  visibility: hidden;
  animation-fill-mode: forwards; /* Behält den Endzustand der Animation bei */
}

.fade-in {
  /* Anwenden der Animation mit Dauer und Verzögerung */
  visibility: visible; /* Stellen Sie sicher, dass das Element während der Animation sichtbar wird */
}
.fade-in-delay-1 { animation: fadeIn 1s ease-out forwards;  }
.fade-in-delay-2 {animation-delay: 5s; animation: fadeIn 1s ease-out forwards;  }
.fade-in-delay-3 {animation-delay: 10s; animation: fadeIn 5s ease-out forwards;  }
.fade-in-delay-4 { animation-delay: 2s; }
