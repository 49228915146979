@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@font-face {
    font-family: 'Mogila';
    src: url('../../fonts/Mogila.otf') format('opentype');
  }

.content {
     /* Zentriert den Text und setzt einen oberen und unteren Abstand */
     max-width: auto; /* Begrenzt die Breite des Fließtextes für bessere Lesbarkeit */
     
     opacity: 0;
     margin-left: auto;
     transition: opacity 1s ease-out;
     text-align: left; 
       font-family: 'Nunito', sans-serif;
       font-weight: 300;
       font-size: 23px !important;
       transition: opacity 3s ease-out;
  }
  
  .content.visible {
    opacity: 1;
  }

  /* Container für jeden Abschnitt, um Layout und Abstand zu steuern */

  .all{
    max-width: 50%; /* oder einen bestimmten Prozentsatz */
    margin: 20 auto; /* Zentriert den Container */
    padding: 20px;
    margin-top: 2%;

  }

  .section {
    margin-bottom: 0%;
    margin-inline: auto;
    max-width: 50%;
  }  
  .sections {
    margin-bottom: 5%;

  }  
  .third{
    grid-area: image;
    height: 85vh;
    width: auto;
    margin-left: 20%;
    margin-top: 0%;
    justify-self: center;
}
  

/* Stellen Sie sicher, dass die Text-Container die korrekten Bereiche zugewiesen bekommen */
.text1 { grid-area: text; }
.text2 { grid-area: text; }
.text3 { grid-area: text; }

@media (max-width: 768px) {
  .third{
    display: none;
}

/* Basierend auf Ihrer vorhandenen Klasse für das Grid-Layout */

.alltopContent { 
  margin-left: 10%;
  max-width: 80%;
 
  grid-template-columns: 1fr; /* Eine einzelne Spalte für mobile Geräte */
  grid-template-areas:
    "text"
    "image";
}
.content { 
  
}

.section{
  margin-top: 20%;
  margin-bottom: 30%;
  grid-area: text;
  max-width: 100%;
}

/* Stellen Sie sicher, dass die Text-Container die korrekten Bereiche zugewiesen bekommen */
.text1 { grid-area: text2; }
.text2 { grid-area: text2; }
.text3 { grid-area: text2; }


}

  @media (max-width: 600px) {

   
  }