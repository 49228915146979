.cookie-banner {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #c1b3a5;
    font-family:'Nunito', sans-serif;
    font-weight: 600;
    color:white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }
  
  /* Responsive Design für Mobilgeräte */
  @media (max-width: 768px) {
    .cookie-banner {
        position: fixed;
        margin: auto;
        right: 0px;
        margin-inline: 5%;
    }
  }
  .cookie-banner-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    color: #574c4c;
    background-color: #f0f0f0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  .cookie-banner-button:hover{
    background-color: #d8d8d8;
  }
  /* Stil für den 'Alle Cookies erlauben' Button */
