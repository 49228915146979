@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@font-face {
  font-family: 'Mogila';
  src: url('../../fonts/Mogila.otf') format('opentype');
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  
  background-size: cover; /* Deckt den gesamten verfügbaren Raum ab */
  background-position: left;
}

.logo{
  fill:white;
  width: 100px;  /* Breite des SVG */
  height: 120px; 
  margin-top: 5%;
  align-items: center;
}
.nameHead{
  font-size: 22px;
  margin:auto;
}
.sloganHead{
  font-size: 15px;
  margin:auto;
  letter-spacing:2.1px;
  margin-left: 3px;
}
.menuHeader{
  align-items: center;
  display: block;
  justify-content: center;
  text-align: center;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  
}

.navbar {
  z-index: 10;
  height: 80px;/* Weiß */
  color:white;
  transition: transform 0.5s ease;
  transform: translateY(0%);
  opacity: 1;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateY(0);
  transition: background-color 1s ease;
}

.navbar.hide {
  transform: translateY(-100%);
  pointer-events: auto;
  transition: opacity 1s ease, transform 0.5s ease,   visibility 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.navbar.show {
  transform: translateY(0%);
  pointer-events: auto; /* Ändere dies zu auto, wenn du Interaktionen zulassen möchtest, wenn die Navbar sichtbar ist */
  transition: opacity 1s ease, transform 0.5s ease;
  opacity: 1;
  visibility: visible; 
}

.menu-icon {
  display: none;
  
}

.nav-elements {
  transition: background-color 1s ease;
}

.nav-elements ul {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  height: 80px;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
 /* color: #2f234f;*/
  color:white;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
  
}
.selected-lang {
  color: #574c4c;/* Unterstreicht das ausgewählte Sprachelement */
  padding-bottom: 2px; /* Erhöht den Abstand zum Unterstrich */
  border-bottom: 2px solid currentColor; /* Macht den Unterstrich dicker */
 
}
@media (max-width: 1280px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 1300px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    
    position: absolute;
    right: 0;
    top: 80px;
    opacity: 1;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 180px;
  }
  .nav-elements ul a {
    font-size: 18px;}
  .nav-elements ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
@media (max-width: 768px) {
  .nameHead{
    font-size: 14px;
    margin:auto;
  }
  .sloganHead{
    font-size: 11px;
    
    letter-spacing:1.8px;
  }
  .menuHeader{
    display: block;
    justify-content: center;
    text-align: center;
  }
  .logo{
    margin-top: 6%;
    width: 80px;  /* Breite des SVG */
    height: 70px; 
    
  }
}
@media (max-width: 450px) {
  .sloganHead{
    font-size: 11px;
    margin:auto;
    letter-spacing:0.6px;
  }
}