@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

@font-face {
    font-family: 'Mogila';
    src: url('../../fonts/Mogila.otf') format('opentype');
  }

.contact-page {
    padding: 20px;
    margin: auto;
    margin-top: 10%;

  }
  .heading {
    margin: auto;
    margin-top: 10%;
    text-align: center;
    margin-bottom: 20px;
    font-family:"Julius Sans One", sans-serif;
    font-weight: 300;
    font-size: 4.5vw;
    max-width: 90%;
    align-items: center;
    transition: transform 0.3s ease;
}
.heading:hover {
  transform: scale(1.05) ;

  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.contact-info-konakt{
  max-width: fit-content;
  margin: auto;
  margin-bottom: 20%;
  margin-top: 0;
  margin-inline: auto;


}

.text-style {
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   /* Stellt sicher, dass der Hintergrund und die Polsterung richtig angewendet werden */
}
.text-wrapper-kontakt {
  transition: transform 0.3s ease;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  padding: 20px;
  font-size: 23px;
 
  margin: auto;
  /* Anpassen nach Bedarf */
}
.hLinked { 
  display: flex;
  margin-inline: auto;
  font-size: clamp(40px, 6vw, 55px);

  justify-content: center;
  transition: transform 0.3s ease;
  margin-bottom: 10%;
  transform-origin: center; 
  overflow: hidden;
  min-width: 0; /* Verhindert, dass das Element zu klein wird */
  max-width: 70%;
}
.hLinked:hover { 
  transform: scale(1.05);
}
.title-wrapper-kontakt {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
 
  font-size: 24px;
  /* Anpassen nach Bedarf */

  /* Für eine kräftige Überschrift */
  
  /* Farbanpassung */
  text-align: center;
    padding: 10px;
    white-space: nowrap;
    font-size: clamp(30px, 5vw, 30px);
    
    text-align: center;
    margin: auto ;
    
      font-weight: 300;

  text-align: center;

    padding: 5px;
    padding-inline: 20px;
   margin-top: 10px;
    

    transition: transform 0.3s ease;

}

.title-wrapper-kontakt:hover {
  transform: scale(1.03);
  /* Skaliert das Element leicht */
 
  /* Fügt einen Schatten hinzu */
}
.felder-kontakt{

  /* Optional: Anpassung für Abstand */

}
@media (max-width: 768px) {

  .heading {
    margin: auto;
    margin-top: 30%;
    width: 90%;

    font-size: clamp(45px, 4vw, 45px);
}
.contact-info-konakt{
  max-width: 100%;
 
  margin: auto;
  margin-inline: auto;
  margin-bottom: 20%;
  margin-top: 0;

  justify-content: center;
  align-items: center;
}

}